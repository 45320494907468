
















import {Component, Vue} from 'vue-property-decorator';
import {UserStore} from '@/store/modules/user';

@Component
export default class UserProfile extends Vue {

  email = UserStore.email;
  name = UserStore.name;
  loading = false;

  update() {
    this.loading = true;
    UserStore.updateProfile(this.email, this.name)
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }
}
